  .focus {
    border: none;
    outline: none;
    padding: 10px 16px;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .active, .focus:hover {
    color: white;
    text-decoration: underline;
  }
 

  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    /* padding: 10px; */
    z-index: 1;
  } 

